import React from "react";
import ContentThx from "./ContentThx/ContentThx";
import "./ContentThanks.scss";
import {useGetThanksInfo} from "../../service/ThanksService";
import Spinner from "../Objets/Spinner";
import FooterContent from "../FooterContent/FooterContent";

type ContentThanksProps = {};

const ContentThanks: React.FunctionComponent<ContentThanksProps> = () => {

    const {data: dataThanks, loading, error} = useGetThanksInfo();

    return (
        <div className={"allContentThx"}>
            <div className={"contentRowAllThx"}>
                <div className={"contentColumnAllThx"}>

                    <div className={"divTitle"}>
                        Remerciements prestataires
                    </div>
                    {dataThanks &&
                        <div className={"divDataPrestataire"}>
                            {
                                dataThanks && dataThanks.map((e: any, index: React.Key) => {
                                    return (<ContentThx data={e} key={index}/>)
                                })
                            }
                        </div>
                    }
                    {loading && <Spinner size={"large"}/>}
                    {error && <div>error</div>}
                </div>
            </div>
            <FooterContent />
        </div>
    );
}
export default ContentThanks;