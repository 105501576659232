import React from 'react';
import "./Spinner.scss";

interface FCSpinnerProps {
    size?: "small" | "middle" | "large";
    dark?: boolean;
}

const Spinner: React.FunctionComponent<FCSpinnerProps> = ({size = "small", dark = false}) => {
    return <span
        className={(dark ? "spinnerColorDark " : "spinnerColor ") +
            (size === "small" ? " spinnerStyleSmaller" : size === "middle" ? " spinnerStyleMiddle" : " spinnerStyleLargest")}/>;
};

export default Spinner;