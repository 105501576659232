import {createContext, useEffect, useState} from "react";
import {EnumTypeSize} from "../types/EnumTypeSize";

interface EventUpdatedSize {
    windowSize: EnumTypeSize;
}

const EventUpdatedSizeContext = createContext<EventUpdatedSize>({} as EventUpdatedSize);

const EventUpdatedSizeProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    let [windowSize, setWindowSize] = useState<EnumTypeSize>(EnumTypeSize.LARGE);

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () =>
            window.removeEventListener("resize",updateDimensions);
    }, [])

    const updateDimensions = () => {
        if (window.outerWidth < 550) {
            setWindowSize(EnumTypeSize.SMALL);
        }
        else if (window.outerWidth < 1024) {
            setWindowSize(EnumTypeSize.MEDIUM);
        }
        else {
            setWindowSize(EnumTypeSize.LARGE);
        }
    }

    return (
        <EventUpdatedSizeContext.Provider value={{windowSize}}>
            {children}
        </EventUpdatedSizeContext.Provider>

    );
};

export {EventUpdatedSizeProvider, EventUpdatedSizeContext};