import React from 'react';

import {useNavigate} from "react-router-dom";

import './Error404.scss';

const Error404: React.FunctionComponent = () => {

    const navigate = useNavigate();
    return (
        <React.Fragment>
            <div className={"errorPage"}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div className={"div_message"}>
                        <div className={"div_title_error"}>
                            <div className={"div_logo_error"}>
                                <img src={`${process.env.PUBLIC_URL}/favicon.png`}
                                     alt={"logo_app"} height={75} width={75}/>
                            </div>
                        </div>
                        <h3>PAGE NOT FOUND</h3>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <p>La page que vous tentez d'accéder n'existe pas.</p>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                            <button onClick={() => navigate("/")}>Accueil</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Error404;