import React from "react";
import "./FooterContent.scss"
import packageInfo from "../../../package.json";
import {useFetchVersion} from "../../service/AdminService";

type FooterContentProps = {};

const FooterContent: React.FunctionComponent<FooterContentProps> = ({}) => {
    const {data: version_back} = useFetchVersion();

    return (
        <div className={"footerDashboard"}>Design et Développement réalisé par Alice & Yannick MARCHETAUX
            (v.{packageInfo.version}/v.{version_back ? version_back.version_back : ""})</div>
    );
}

export default FooterContent;