import React, {useState} from "react";
import "./HeaderHome.scss"
import Spinner from "../Objets/Spinner";
import {LuDownload} from "react-icons/lu";
import {AppSettings} from "../../AppSettings";

type HeaderHomeProps = {
    setMenuSelected: any;
};

const HeaderHome: React.FunctionComponent<HeaderHomeProps> = ({setMenuSelected}) => {
    const [hamburgerToggle, setHamburgerToggle] = useState(false);

    const [dlArchivePhotobooth, setDlArchivePhotobooth] = useState(false);
    const [dlArchivePhotographe, setDlArchivePhotographe] = useState(false);

    const onClick = async (typeMenu: string) => {
        setMenuSelected(typeMenu);
        setHamburgerToggle(false);
    }

    const onClickDlPhotobooth = () => {
        setDlArchivePhotobooth(true);
        window.open(AppSettings.API_ENDPOINT+"/photos/archives/photobooth.zip", "_blank");
        setDlArchivePhotobooth(false);
    }

    const onClickDlPhotographe = () => {
        setDlArchivePhotographe(true);
        window.open(AppSettings.API_ENDPOINT+"/photos/archives/photographe.zip", "_blank");
        setDlArchivePhotographe(false);
    }

    const toggleHamburger = () => {
        setHamburgerToggle(!hamburgerToggle);
    }

    return (
        <nav className={"headerDashboard"}>

            <div className={hamburgerToggle ? "menuBar show" : "menuBar"}>

                <div className={"menuItem"}>
                    <div style={{"display": "flex", "flexDirection": "row"}}>
                        <div style={{"marginRight": "5px"}} className={"cursorItem"}
                             onClick={() => onClick("photobooth")}>PhotoBooth
                        </div>
                        <div style={{"display": "flex", "flexDirection": "column", "justifyContent": "center"}}>
                            {dlArchivePhotobooth &&
                                <Spinner size={"small"}/>
                            }
                            {!dlArchivePhotobooth &&
                                <LuDownload className={"cursorItem"} size={20} onClick={() => onClickDlPhotobooth()}/>
                            }
                        </div>
                    </div>
                </div>
                <div className={"menuItem"}>
                    <div style={{"display": "flex", "flexDirection": "row"}}>
                        <div style={{"marginRight": "5px"}} className={"cursorItem menuTitre"}
                             onClick={() => onClick("titre")}>Alice & Yannick
                        </div>
                        <div style={{"display": "flex", "flexDirection": "column", "justifyContent": "center"}}>
                            {dlArchivePhotographe &&
                                <Spinner size={"small"}/>
                            }
                            {!dlArchivePhotographe &&
                                <LuDownload className={"cursorItem"} size={20} onClick={() =>  onClickDlPhotographe()}/>
                            }
                        </div>
                    </div>
                </div>
                <div className={"menuItem cursorItem"} onClick={() => onClick("thx")}>Remerciements</div>
            </div>
            <div className="divToggle" >
                <div style={{"display" : hamburgerToggle ? "none" : "flex"}} className={"menuTitre"}>Alice & Yannick</div>

                <div className="menuToggle" onClick={() => toggleHamburger()}>
                    <span className="iconBar"></span>
                    <span className="iconBar"></span>
                    <span className="iconBar"></span>
                </div>
            </div>
        </nav>
    );
}

export default HeaderHome;