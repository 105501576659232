import React, {useContext, useEffect, useState} from "react";
import PhotoAlbum from "react-photo-album";
import "./ContentPhoto.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {useGetInfoPhoto} from "../../service/ImagesService";
import UtilPhotos from "../../utils/UtilPhotos";
import {EnumTypePhoto} from "../../types/EnumTypePhoto";
import Spinner from "../Objets/Spinner";
import {EventUpdatedSizeContext} from "../../store/EventUpdatedWidthContext";
import {EnumTypeSize} from "../../types/EnumTypeSize";
import FooterContent from "../FooterContent/FooterContent";

type ContentPhotoProps = {
    type: EnumTypePhoto;
};

const ContentPhoto: React.FunctionComponent<ContentPhotoProps> = (props) => {
    const [index, setIndex] = useState(-1);
    const [photos, setPhotos] = useState<any>(undefined);
    const [oldType, setOldType] = useState<EnumTypePhoto>();
    const {data: infosPhotos, loading, error} = useGetInfoPhoto(props.type);

    const {windowSize} = useContext(EventUpdatedSizeContext);

    useEffect(() => {
        setPhotos(undefined);
        if (infosPhotos && !loading && props.type === oldType) {
            setPhotos(UtilPhotos.getPhotos(props.type, infosPhotos));
        }
        setOldType(props.type) // < fix pour éviter certaines erreurs de récupération d'image
    }, [props.type, oldType, infosPhotos, loading]);

    return (
        <React.Fragment>
            {loading && <div style={{"display": "flex", "justifyContent": "center"}}><Spinner size={"large"}/></div>}
            {(photos) &&
                <React.Fragment>
                    <div className={"contentPhoto"}>
                        <PhotoAlbum photos={photos} layout={"masonry"}
                                    columns={windowSize === EnumTypeSize.MEDIUM ? 2 : windowSize === EnumTypeSize.SMALL ? 1 : 3}
                                    onClick={({index}) => setIndex(index)}/>
                        <Lightbox
                            slides={photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}

                            // enable optional lightbox plugins
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Download]}
                        />
                    </div>
                    <FooterContent/>
                </React.Fragment>
            }
            {error && <div>error</div>}
        </React.Fragment>
    );
}
export default ContentPhoto;