import jwt_decode from "jwt-decode";

const tokenKey = "authToken";

const getToken = () : string | null => {
    return window.localStorage.getItem(tokenKey);
}

const setToken = (token: string):void => {
    window.localStorage.setItem(tokenKey, token);
}

const clearToken = () : void => {
    window.localStorage.removeItem(tokenKey);
}

const verifyToken = () : boolean => {
    const t = window.localStorage.getItem(tokenKey);
    if (t) {
        const decodeToken: any = jwt_decode(t.split(" ")[1]);
        if (!decodeToken) {
            return false;
        }
        if (decodeToken.exp < Date.now() / 1000) {
            clearToken();
        }
        return true
    }
    return false;
}

export {
    getToken,
    setToken,
    clearToken,
    verifyToken
};