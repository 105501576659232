import React, {useState} from "react";
import "./Home.scss"
import HeaderHome from "../../components/HeaderHome/HeaderHome";
import ContentPhoto from "../../components/ContentPhoto/ContentPhoto";
import {EnumTypePhoto} from "../../types/EnumTypePhoto";
import ContentThanks from "../../components/ContentThanks/ContentThanks";
import Login from "../Login/Login";
import {getToken, verifyToken} from "../../utils/storage/Token";

function Home() {

    const [menuSelected, setMenuSelected] = useState("");

    verifyToken();
    if (!getToken()) {
        return (
            <Login/>
        )
    }

    return (
        <div className={"dashboard"}>
            <HeaderHome setMenuSelected={setMenuSelected}/>
            <div className={"contentDashboard"}>
                <div className={"absolute layoutContent"}>
                    {((menuSelected && menuSelected === "photobooth") &&
                            <ContentPhoto type={EnumTypePhoto.PHOTOBOOTH}/>) ||
                        ((menuSelected && menuSelected === "thx") &&
                            <ContentThanks />) ||
                        <ContentPhoto type={EnumTypePhoto.PHOTOGRAPHE}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Home;