import React from "react";
import {InterfacePrestataire} from "../../../service/interfaces/InterfacePrestataire";
import "./ContentThx.scss";
import {AppSettings} from "../../../AppSettings";

type ContentThxProps = {
    data: InterfacePrestataire;
};

const ContentThx: React.FunctionComponent<ContentThxProps> = (props) => {
    return (
        <React.Fragment>
            <div className={"divContentThx"}>
                <div className={"divCenterImage"}>
                <div>
                    <img src={`${AppSettings.API_ENDPOINT}/photos/prestataires/${props.data.namePhotoPrestataire}`}
                         alt={"prestataire"} className={"imagePrest"}  />
                </div>
                </div>
                <div className={"divDescription"}>
                    <div>{props.data.thanks.pre} <i>{props.data.thanks.name}</i></div>
                    <a className={"linkThx"} href={"tel:"+props.data.contact.tel}>{props.data.contact.tel}</a>
                    <a className={"linkThx"} href={props.data.contact.page}>{props.data.contact.page}</a>
                </div>
            </div>
        </React.Fragment>
    );
}
export default ContentThx;