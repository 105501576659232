import {AppSettings} from "../AppSettings";

class UtilPhotos {
    public static Breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

    public static UnsplashLinkSrcSet = (type: string, width: number, name: string) => `${AppSettings.API_ENDPOINT}/photos/${type}/${width}/${name}`;
    public static UnsplashLink = (type: string, name: string) => `${AppSettings.API_ENDPOINT}/photos/${type}/${name}`;

    public static getPhotos(type: string, listInfo: []) {
        return listInfo.map((photo: any) => ({
            src: UtilPhotos.UnsplashLink(type, photo.name),
            width: photo.width,
            height: photo.height,
            title: photo.name,
            srcSet: UtilPhotos.Breakpoints.map((breakpoint) => {
                const height = Math.round((photo.height / photo.width) * breakpoint);
                return {
                    src: UtilPhotos.UnsplashLinkSrcSet(type, breakpoint, photo.name),
                    width: breakpoint,
                    height,
                };
            }),
        }));
    }
}

export default UtilPhotos;